import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Footer from '@sections/Footer';
import ProjectDetails from '@common/ProjectDetails';

export const ProjectsTemplate = ({ title, type, image, content }) => (
  <div className="projectPreview">
    <div className="inner">
      {image ? <img alt="Featured" className="image" src={image} /> : null}
      <h3 className="title">{title}</h3>
      <div className="type">{type}</div>
      <div className="content">{content}</div>
    </div>
  </div>
);

const Project = ({ data }) => {
  const { title, type, images } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  return (
    <Layout>
      <Navbar />
      <ProjectDetails title={title} type={type} images={images} html={html} />
      <Footer />
    </Layout>
  );
};

export default Project;

export const query = graphql`
  query ProjectBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        templateKey
        title
        type
        images {
          childImageSharp {
            fluid(maxWidth: 3840) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
      html
    }
  }
`;
