import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import Fade from 'react-reveal/Fade';
import Slider from 'react-slick';

import { Container, Section } from '@components/global';
import Button from '@common/Button';
import defaultImage from '@images/jsb.jpg';
import arrowLeft from '@images/icons/arrow-left.png';
import arrowRight from '@images/icons/arrow-right.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const PrevArrow = props => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: `url(${arrowLeft})`,
        backgroundSize: 'cover',
      }}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    />
  );
};

const NextArrow = props => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: `url(${arrowRight})`,
        backgroundSize: 'cover',
      }}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    />
  );
};

const settings = {
  dots: true,
  infinite: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

class SimpleSlider extends React.Component {
  componentDidMount() {
    window.addEventListener('touchstart', this.touchStart);
    window.addEventListener('touchmove', this.preventTouch, { passive: false });
  }

  componentWillUnmount() {
    window.removeEventListener('touchstart', this.touchStart);
    window.removeEventListener('touchmove', this.preventTouch, {
      passive: false,
    });
  }

  touchStart(e) {
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
  }

  preventTouch(e) {
    const minValue = 5; // threshold

    this.clientX = e.touches[0].clientX - this.firstClientX;
    this.clientY = e.touches[0].clientY - this.firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(this.clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  }

  render() {
    const { images } = this.props;
    return (
      <Slider {...settings}>
        {images.map(image => {
          const { fluid } = image.childImageSharp;
          return (
            <div key={image}>
              <Image imgStyle={{ objectFit: 'contain' }} fluid={fluid} />
            </div>
          );
        })}
      </Slider>
    );
  }
}

const ProjectDetails = ({ title, type, images, html }) => (
  <Wrapper>
    <Fade>
      <Images>
        {images ? (
          <SimpleSlider images={images} />
        ) : (
          <Img>
            <img src={defaultImage} alt="placeholder" />
          </Img>
        )}
      </Images>
      <Container>
        <Heading>{title}</Heading>
        <Type>{type}</Type>
        <Text dangerouslySetInnerHTML={{ __html: html }} />
        <Buttons>
          <Link to="/projekt">
            <Button primary>Tillbaka</Button>
          </Link>
        </Buttons>
      </Container>
    </Fade>
  </Wrapper>
);

export default ProjectDetails;

const Wrapper = styled(Section)`
  .slick-list {
    height: 80vh;
    max-height: calc(100vw / 3 * 2);
  }

  .slick-list div {
    height: 100%;
  }

  .slick-dots {
    bottom: -40px;
  }

  padding-top: 55px;

  @media (min-width: ${props => props.theme.screen.md}) {
    .slick-prev,
    .slick-next {
      top: 50%;
      z-index: 9999;
      box-shadow: 0 1px 6px rgba(1, 1, 1, 0.16);
      border-radius: 100%;
      &:hover {
        box-shadow: none;
      }
      &:before {
        color: ${props => props.theme.color.text.primary};
        content: '';
      }

      background-size: contain;
    }
    .slick-prev {
      left: 40px;
      &:before {
        background-image: url(${arrowLeft}) !important;
      }
    }
    .slick-next {
      right: 40px;
      &:before {
        background-image: url(${arrowRight}) !important;
      }
    }
    .slick-prev,
    .slick-next,
    .slick-dots button {
      &:focus {
        outline: 5px solid #9ecaed;
        box-shadow: 0 0 10px #9ecaed;
      }
    }
  }
`;

const Images = styled.div`
  .gatsby-image-wrapper.portrait {
    max-height: 100%;
  }
`;

const Buttons = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
`;

const Heading = styled.h3`
  margin-top: 50px;
  margin-bottom: 0;
`;

const Type = styled.p`
  margin-top: 0;
  font-weight: bold;
  opacity: 0.6;
`;

const Text = styled.div`
  img {
    max-width: 100%;
  }
`;

const Img = styled.div`
  max-height: 70vh;
  overflow: hidden;
  img {
    width: 100%;
  }
`;
